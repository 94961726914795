<template>
  <div class="home">
    <Page404 msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Page404 from '../components/404.vue'
export default {
  name: 'Home',
  components: {
    Page404
  }
}
</script>

<style>
</style>